<template>
	<div class="expert-detail">

		<div class="expert-detail-con c w">
			<el-breadcrumb separator=">">
				<el-breadcrumb-item :to="{ path: '/expert/index' }">评委专家库</el-breadcrumb-item>
				<el-breadcrumb-item>专家详情</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="expert-content" v-if="obj.detail">
				<div class="desc-warp">
					<el-image :src="obj.detail.bgImg">
						<template #error>
							<img class="error-img" src="~@/assets/home/empty.png" alt="">
						</template>
					</el-image>
					<h3 class="name">{{ obj.detail.name }}</h3>
					<p class="leave">{{ obj.detail.unionName }}</p>
					<p class="leave">{{ obj.detail.unionTitle }}</p>
				</div>
				<div class="introduction-warp">
					<div v-html="obj.detail.introduction"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		reactive
	} from 'vue'
	import {
		useRoute
	} from 'vue-router';

	import {
		getExpertInfo
	} from '@/api/competition'
	const route = useRoute()

	let obj = reactive({
		detail: {}
	})
	getExpertInfo({
		id: route.query.id
	}).then(res => {
		console.log(res);
		obj.detail = res.data
	})
</script>

<style lang="scss" scoped>
	.el-breadcrumb {
		font-size: 22px;
		padding: 30px 0;
	}

	.el-breadcrumb__separator {
		color: #000;
	}

	.expert-content {
		display: flex;
		justify-content: space-between;
		color: #595757;
		border-top: 1px solid #e5e5e5;
		padding: 40px 0;

		.desc-warp {
			width: 320px;

			.el-image {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}

			.error-img {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}

			.name {
				color: #000;
				margin: 20px 0;
			}

			.leave {
				margin: 20px 0;
			}
		}

		.desc-ch {
			margin: 10px 0 20px 0;
		}

		.desc-en {
			word-break: keep-all;
			font-size: 12px;
		}

		.introduction-warp {
			width: 800px;

			.in-en {
				margin-top: 50px;
			}
		}

	}
</style>
